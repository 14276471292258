<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          @click="openFileBrowser"
          :loading="loading"
          v-on="on"
        >
          <v-icon>fa fa-cloud-upload-alt</v-icon>
        </v-btn>
      </template>

      <span>{{'action.upload'| t}}</span>
    </v-tooltip>

    <invisible-photo-uploader
      ref="invisiblePhotoUploader"
      @onLoadingChange="onLoadingChange"
      @appendPhoto="appendPhoto"
      :type="type"
    ></invisible-photo-uploader>
  </div>
</template>

<script lang="babel" type="text/babel">
import photoItemAction from '@/components/photoSelector/action/photoItemAction'
export default {
  mixins: [photoItemAction],
  props: {

  },
  data: () => ({
    loading: false,
  }),
  methods: {
    openFileBrowser() {
      this.$refs.invisiblePhotoUploader.openFileBrowser()
    },
    onLoadingChange(loading) {
      this.loading = loading
    },
    appendPhoto(photo) {
      this.$emit('changePhoto', photo)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>